<template>
  <div v-if="checkLayout">
    <div v-if="data.layout === 'one-column-grid'" class="one-column-grid-layout">
      <CalendarOneColumnGrid :content="data" :limit="limit" :expandedVwTxt="getExpandedViewText"> </CalendarOneColumnGrid>
    </div>
    <div v-else-if="data.layout === 'two-column-grid'" class="two-column-grid-layout">
      <CalendarTwoColumnGrid :content="data" :expandedVwTxt="getExpandedViewText"> </CalendarTwoColumnGrid>
    </div>
  </div>
  <div v-else>
    <div v-if="layout === 'one-column-list' || data.layout === 'one-column-list'" class="one-column-list-layout">
      <CalendarOneColumnList :content="data" :limit="limit" :expandedVwTxt="getExpandedViewText"> </CalendarOneColumnList>
    </div>
    <div v-else-if="data.layout === 'two-column-list'" class="two-column-list-layout">
      <CalendarTwoColumnList :content="data" :limit="limit" :expandedVwTxt="getExpandedViewText"> </CalendarTwoColumnList>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'CalendarContentWidget',
  components: {
    CalendarOneColumnGrid: () => import('./CalendarOneColumnGrid.vue'),
    CalendarTwoColumnGrid: () => import('./CalendarTwoColumnGrid.vue'),
    CalendarOneColumnList: () => import('./CalendarOneColumnList.vue'),
    CalendarTwoColumnList: () => import('./CalendarTwoColumnList.vue'),
  },
  data() {
    return {
      isDayClicked: false,
      eventsList: this.data.eventsResults,
      selectedDate: moment(new Date()).format('MM-DD-YYYY'),
    }
  },
  props: {
    data: { type: Object, required: true },
    isExpandedView: {
      type: Boolean,
      default: false,
    },
    expandedVwTxt: { type: String },
  },
  computed: {
    checkLayout() {
      return this.layout === 'one-column-list' ? false : this.data.layout.indexOf('grid') > -1
    },
    limit() {
      return this.isExpandedView ? this.data.expViewItemCnt : this.data.dashboardItemCnt
    },
    layout() {
      return this.isExpandedView ? this.data.layout : 'one-column-list'
    },
    getExpandedViewText() {
      return this.isExpandedView ? this.expandedVwTxt : ''
    },
  },
}
</script>
